<template>
    <div class="content choiceCar">
        <div class="choiceCar-tit">当前位置：模拟考试</div>
        <div class="choiceCar-con flex flex-jc">
            <div
                class="choiceCar-tab fl"
            >
                <div class="choiceCar-tab-tit">车型选择</div>
                <div class="choiceCar-tab-item">
                    <p
                        v-for="item in car.car"
                        :key="item.Id"
                        :class="item.Id === car.selectId ? 'tab-item-line' : ''"
                        @click="tabChoose(item.Id)"
                    >
                        <span class="tab-item-img01">
                            <img :src="item.ImgUrl || require('../../images/seat.png')" />
                        </span>
                        <samp :class="car.selectId === item.Id ? 'tab-txt-lin' : ''">{{item.Name}}</samp>
                    </p>
                </div>
            </div>
            <div class="choiceCar-subject fr">
                <div class="subject-con">
                    <div class="flex subject-tab">
                        <p
                            :class="topicId=== 1 ? 'sjt-tab-lin01' : ''"
                            @click="sleTopic(1)"
                        >科目一</p>
                        <p
                            :class="topicId==4 ? 'sjt-tab-lin02' : ''"
                            @click="sleTopic(4)"
                        >科目四</p>
                    </div>
                    <article class="choiceCar-c01">
                        <div
                            class="choiceCar-mun flex flex-sb"
                            v-for="(cate,cateIndex) in categoryList"
                            :key="cateIndex"
                        >
                            <div class="choiceCar-mun-tit mun-nav">{{ cate.Name }}</div>
                            <ul class="subject-topic flex flex-fw choiceCar-mun-list">
                                <template v-for="(item,index) in cate.ChildLilst">
                                    <li
                                        :key="index"
                                        v-if="item.Id === -2"
                                        @click="exam(cate.Id)"
                                    >
                                        <p>
                                            <img
                                                class="icon-img"
                                                :src="item.ImgUrl || require('../../images/seat.png')"
                                            />
                                        </p>
                                        <span>{{item.Name}}</span>
                                    </li>
                                    <li
                                        :key="index"
                                        v-else-if="item.Id === -1"
                                        @click="$router.push({ path:'/examExercises',query:{id:item.Id,type:topicId}})"
                                    >
                                        <p>
                                            <img
                                                class="icon-img"
                                                :src="item.ImgUrl || require('../../images/seat.png')"
                                            />
                                        </p>
                                        <span>{{item.Name}}</span>
                                    </li>
                                    <li
                                        :key="index"
                                        v-else-if="item.HasChildProblem"
                                        @click="$router.push({ path:'/examExercises',query:{id:item.Id,type:topicId}})"
                                    >
                                        <p>
                                            <img
                                                class="icon-img"
                                                :src="item.ImgUrl || require('../../images/seat.png')"
                                            />
                                        </p>
                                        <span>{{item.Name}}</span>
                                    </li>
                                    <li
                                        :key="index"
                                        v-else-if="item.HasChildCategory"
                                        @click="$router.push({path:`/skillTest/${item.Id}`,query:{name:item.Name,type:topicId}})"
                                    >
                                        <p>
                                            <img
                                                class="icon-img"
                                                :src="item.ImgUrl || require('../../images/seat.png')"
                                            />
                                        </p>
                                        <span>{{item.Name}}</span>
                                    </li>
                                    <li
                                        :key="index"
                                        v-else
                                        @click="noCategory"
                                    >
                                        <p>
                                            <img
                                                class="icon-img"
                                                :src="item.ImgUrl || require('../../images/seat.png')"
                                            />
                                        </p>
                                        <span>{{item.Name}}</span>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import '../../styles/public.scss';
@import 'choiceCar.scss';
.icon-img {
    // width: 50px;
    height: 50px;
}
</style>
<script>
import { mapState } from 'vuex';

export default {
    name: '',
    computed: {
        ...mapState(['car'])
    },
    data() {
        return {
            topicId: 1,
            categoryList: []
        };
    },
    created() {
        this.getData();
        this.topicId = this.$route.query.type > 0 ? this.$route.query.type : 1;
    },
    methods: {
        /**
         * 选择车型
         */
        tabChoose(id) {
            if (id === this.car.selectId) {
                return;
            }
            this.$store.dispatch('car/setSelectId', id);
            this.getData();
        },
        /**
         * 切换科目
         */
        sleTopic(id) {
            if (this.topicId === id) {
                return;
            }
            this.topicId = id;
            this.getData();
        },
        /**
         * 获取数据
         */
        getData() {
            const model = {
                carId: this.car.selectId,
                type: this.topicId
            };
            this.$api.getProblemCategoryByCarId(model).then(res => {
                if (res.flag) {
                    const lastArr = [{ Id: -1, Name: '我的错题', ImgUrl: require('../../images/icon03.png') }, { Id: -2, Name: '模拟考试', ImgUrl: require('../../images/icon04.png') }];
                    this.categoryList = res.km.map(a => {
                        a.ChildLilst = [...a.ChildLilst, ...lastArr];
                        return a;
                    });
                    // this.categoryList = [...res.km, ...lastArr];
                }
            });
        },
        exam(id) {
            this.$router.push({ path: '/modelTest', query: { type: this.topicId, carId: this.car.selectId, firstCateId: id } });
        },
        noCategory() {
            this.$message({
                type: 'info',
                message: '该分类为空',
                showClose: true
            });
        }
    }
};
</script>
